import { createAction, props } from '@ngrx/store';
import { Event, List, ListInvitee } from 'src/app/core/models/app.models';
import { ImportResult } from 'src/app/features/crm/models/import-result.interface';

export const listOpened = createAction(
  '[My List Container] List opened',
  props<{
    listInvitees: ListInvitee[];
  }>(),
);
export const createNewLinkClicked = createAction(
  '[My List Container] Create New Link for List',
  props<{
    list: List;
  }>(),
);
export const addInviteeToListClicked = createAction(
  '[My List Container] Add Invitee to List Clicked',
  props<{
    listInvitees: ListInvitee[];
    listInvitee: ListInvitee;
  }>(),
);
export const editInviteeClicked = createAction(
  '[My List Container] Edit Invitee Clicked',
  props<{
    listInvitee: ListInvitee;
  }>(),
);
export const checkInOutInvitee = createAction(
  '[My List Container] Check In Invitee Clicked',
  props<{
    listInvitee: ListInvitee;
  }>(),
);
export const deleteInviteeClicked = createAction(
  '[My List Container] Delete Invitee Clicked',
  props<{
    listInvitee: ListInvitee;
  }>(),
);
export const confirmEditInviteeClicked = createAction(
  '[My List Container] Confirm Edit Invitee Clicked',
  props<{
    listInvitee: ListInvitee;
  }>(),
);
export const backClicked = createAction(
  '[My List Container] Back Clicked',
  props<{
    event: Event;
  }>(),
);
export const addInviteeToListSuccessful = createAction(
  '[My List Container] Add Invitee To List Successful',
);
export const addInviteeToListUnsuccessful = createAction(
  '[My List Container] Add Invitee To List Unsuccessful',
);
export const checkInInviteeSuccessful = createAction(
  '[My List Container] CheckIn Invitee Successful',
);
export const createNewLinkForListSuccessful = createAction(
  '[My List Container] Create New Link Successful',
);
export const editInviteeToListSuccessful = createAction(
  '[My List Container] Edit Invitee Successful',
);
export const editInviteeToListUnsuccessful = createAction(
  '[My List Container] Edit Invitee Unsuccessful',
);
export const deleteInviteeSuccessful = createAction(
  '[My List Container] Delete Invitee Successful',
  props<{
    listInvitee: ListInvitee;
  }>(),
);
export const deleteInviteeFailed = createAction(
  '[My List Container] Delete Invitee failed',
  props<{
    listInvitee: ListInvitee;
  }>(),
);
export const openListByUuid = createAction(
  '[My List Container] Open List by Uuid',
);
export const maxInviteesReached = createAction(
  '[My List Container] Max Invitees reached',
);
export const currentTimeOverValidTime = createAction(
  '[My List Container] Current Time is Over the valid time of list',
);
export const importCsv = createAction(
  '[My List Container] Import CSV',
  props<{ file: File }>(),
);
export const importCsvSuccessful = createAction(
  '[My List Container] Import CSV Successful',
);
export const importCsvUnsuccessful = createAction(
  '[My List Container] Import CSV failed',
  props<{ result: ImportResult }>(),
);
export const exportCsvLInviteesFromList = createAction(
  '[My List Container] Export CSV',
  props<{ listId: number }>(),
);
export const getCsvTemplateClick = createAction(
  '[My List Container] Export CSV Template',
);
