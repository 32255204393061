import { createFeature, createReducer, on } from '@ngrx/store';
import { MyListState } from './my-list.state';
import { MyListContainerActions } from './index';
import { OrbitContainerActions } from '../../orbit/store/index';
import {
  addInviteeToList,
  setReadOnlyForInvitee,
  removeInviteeFromList,
  setCheckInOutInvitee,
} from 'src/app/core/helpers/helpers';

export const initialState: Readonly<MyListState> = {
  list: {
    id: 0,
    name: '',
    namePromoter: '',
    emailPromoter: '',
    event: null,
    link: '',
    listType: null,
    maxInvitees: 0,
    price: '',
    validTillDate: '',
    validTillTime: '',
    listInvitee: [],
  },
  listInvitees: [],
};

export const myListReducers = createReducer(
  initialState,
  on(OrbitContainerActions.openListClicked, (state, { list }) => ({
    ...state,
    list: list,
  })),
  on(MyListContainerActions.createNewLinkClicked, (state, { list }) => ({
    ...state,
    list: list,
  })),
  on(MyListContainerActions.listOpened, (state, { listInvitees }) => ({
    ...state,
    listInvitees: listInvitees,
  })),
  on(
    MyListContainerActions.addInviteeToListClicked,
    (state, { listInvitees, listInvitee }) => ({
      ...state,
      list: listInvitee.list!,
      listInvitees: addInviteeToList(listInvitees, listInvitee),
    }),
  ),
  on(MyListContainerActions.editInviteeClicked, (state, { listInvitee }) => ({
    ...state,
    list: listInvitee.list!,
    listInvitees: setReadOnlyForInvitee(state.listInvitees!, listInvitee),
  })),
  on(
    MyListContainerActions.deleteInviteeSuccessful,
    (state, { listInvitee }) => ({
      ...state,
      list: state.list,
      listInvitees: removeInviteeFromList(state.listInvitees!, listInvitee),
    }),
  ),
  on(MyListContainerActions.checkInOutInvitee, (state, { listInvitee }) => ({
    ...state,
    list: listInvitee.list!,
    listInvitees: setCheckInOutInvitee(state.listInvitees!, listInvitee),
  })),
);

export const myListFeature = createFeature({
  name: 'myList',
  reducer: myListReducers,
});
