import * as MyListContainerActions from './actions/my-list-container.actions';
import { myListFeature } from './my-list.reducers';

export * as myListState from './my-list.state';

const { reducer, name, ...myListSelectors } = myListFeature;

export {
  reducer as myListReducer,
  name as myListName,
  MyListContainerActions,
  myListSelectors,
};
